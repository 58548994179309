<template>
  <div style="width: 65%; justify-content: center; margin: 0 auto;">
    <md-card>
      <md-card-header>
        <div class="md-layout md-size-90 md-alignment-center">
          <p class="md-headline">{{ $t('privacy.statement') }}
          </p>
        </div>
      </md-card-header>
      <md-card-content>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-60 md-small-size-90">
            {{ $t('privacy.info') }}
          </div>
        </div>
        <div class="md-layout md-size-90 md-alignment-center">
          <div class="md-layout-item md-size-90 md-small-size-90">
            <p>
              {{ $t('privacy.privacyInfo') }}
            </p>
            <p>
              {{ $t('privacy.advised') }}
            </p>
            <p>
              {{ $t('privacy.notResponsible') }}
            </p>
            <p>
              {{ $t('privacy.principal') }}
            </p>
            <p>
              {{ $t('privacy.cover') }}
            </p>
            <table width="350" border="1" cellspacing="0">
              <tbody>
              <tr bordercolor="white">
                <td width="140" border="0"><b> {{ $t('privacy.organisationName') }}</b></td>
                <td border="0"><b> Wibki Inc.</b></td>
              </tr>

              <tr bordercolor="white">
                <td width="140" border="0"><b> {{ $t('privacy.city') }} </b></td>
                <td border="0"><strong>Tel Aviv, Bilu 39 </strong></td>
              </tr>

              <tr bordercolor="white">
                <td width="140" border="0"><b> {{ $t('privacy.country') }} </b></td>
                <td border="0"><strong>Israel </strong></td>
              </tr>
              <tr bordercolor="white">
                <td width="140" border="0"><b> {{ $t('privacy.phone') }} </b></td>
                <td border="0"><strong>+972-3-5250755 </strong></td>
              </tr>
              <tr bordercolor="white">
                <td width="140"><b> {{ $t('privacy.webSites') }}</b></td>
                <td border="0"><b> http://www.wibki.com </b></td>
              </tr>
              </tbody>
            </table>
            <p>
              {{ $t('privacy.atAMF') }}
            </p>
            <p>
              {{ $t('privacy.collect') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.logFiles') }}
            </p>
            <p>
              {{ $t('privacy.logFilesTxt1') }}
            </p>
            <p>
              {{ $t('privacy.logFilesTxt2') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.cookiesBeacons') }}
            </p>
            <p>
              {{ $t('privacy.cbTxt1') }}
            </p>
            <p>
              {{ $t('privacy.cbTxt2') }}
            </p>
            <p>
              {{ $t('privacy.cbTxt3') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.ads') }}
            </p>
            <p>
              {{ $t('privacy.adsTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.infoCollect') }}
            </p>
            <p>
              {{ $t('privacy.infoCollectTxt1') }}
            </p>
            <p>
              {{ $t('privacy.infoCollectTxt2') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.useOfContactInfo') }}
            </p>
            <p>
              {{ $t('privacy.useOfContactInfoTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.serviceProviders') }}
            </p>
            <p>
              {{ $t('privacy.serviceProvidersTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.confidentiality') }}
            </p>
            <p>
              {{ $t('privacy.confidentialityTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.useInfo') }}
            </p>
            <p>
              {{ $t('privacy.useInfoTxt1') }}
            </p>
            <p>
              {{ $t('privacy.useInfoTxt2') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.linksToOther') }}
            </p>
            <p>
              {{ $t('privacy.linksToOtherTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.policyChildren') }}
            </p>
            <p>
              {{ $t('privacy.policyChildrenTxt') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('privacy.deleteUsers') }}
            </p>
            <p>
              {{ $t('privacy.deleteUsersTxt') }}
            </p>
            <p>
              {{ $t('privacy.deleteUsersTxt1') }}
            </p>
            <p>
              {{ $t('privacy.deleteUsersTxt2') }}
            </p>
            <p>
              {{ $t('privacy.deleteUsersTxt3') }}
            </p>
            <p>
              {{ $t('privacy.deleteUsersTxt4') }}
            </p>
            <p class="md-subheading bold">
              {{ $t('terms.yourFeedback') }}
            </p>
            <p>
              {{ $t('terms.yourInput') }}
            </p>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
export default {
  name: 'Privacy',
};
</script>
